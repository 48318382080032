import React from "react";
import {getPropertyValue} from "../mermaid/MermaidWrapper";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {AttributionTile, getJSON, WithMaxButton} from "./mapUtil";
import {MapContainer, Marker, Popup} from "react-leaflet";
import {toArray} from "../../../components/util";
import InstructionForAction from "../../../components/InstructionForAction";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import DOMContent from "../DOMContent";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const tooltip = 'tooltip';
const latitude = 'latitude';
const longitude = 'longitude';
const markerIconURL = 'markerIconURL';
const markerIconSize = 'markerIconSize';
const markerIconAnchor = 'markerIconAnchor';
const markerShadowIconURL = 'markerShadowIconURL';
const markerShadowIconSize = 'markerShadowIconSize';
const markerShadowIconAnchor = 'markerShadowIconAnchor';
const markersPadding = 'markersPadding';

export const MAP_MARKERS_SETTINGS = [
    {label : "Tooltip HTML Content", stateKey : tooltip},
    {label : "Latitude", stateKey : latitude},
    {label : "Longitude", stateKey : longitude},
    {label : "Padding Around Markers", stateKey : markersPadding, helpText: 'Value should be a valid JSON array e.g. [50, 50]'},
    {label : "Marker Icon URL", stateKey : markerIconURL},
    {label : "Marker Icon Size Array", stateKey : markerIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "Marker Icon Anchor Array", stateKey : markerIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},
    {label : "Marker Shadow Icon URL", stateKey : markerShadowIconURL},
    {label : "Marker Shadow Icon Size Array", stateKey : markerShadowIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "Marker Shadow Icon Anchor Array", stateKey : markerShadowIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},

];


class SparqlResultMapMarkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.load().then(() => {

        });
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;

        let bindings = data?.["results"]?.["bindings"];
        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }

        let markers = resolvedValues.map(p => {
            return L.latLng(p[latitude], p[longitude]);
        }).filter(v => v);
        const latLngBounds = toArray(markers).length >0 ? L.latLngBounds(markers) : undefined;
        this.setState({bounds : latLngBounds, resolvedValues });
    }

    render() {
        let {bounds, resolvedValues, open} = this.state;
        if(toArray(resolvedValues).length === 0) {
            return <>
                <InstructionForAction text={
                    'No results found.'
                }/>
            </>;
        }
        let padding = resolvedValues.find(rv => rv[markersPadding]);

        const content = <MapContainer
            bounds={bounds}
            style={{height: open ? "calc(80vh - 124px)" : "100%", zIndex: 0}}
            ref={(ref) => {
                this.mapRef = ref;
            }}
            boundsOptions={{padding: getJSON(padding?.[markersPadding], [50, 50])}}
        >
            <AttributionTile></AttributionTile>
            {resolvedValues.map(rv => {
                const icon = rv[markerIconURL] ? new L.icon({
                    iconUrl:  rv[markerIconURL],
                    iconSize: getJSON(rv[markerIconSize], [24, 24]),
                    iconAnchor: getJSON(rv[markerIconAnchor], [12, 24]),
                    shadowUrl: rv[markerShadowIconURL],
                    shadowSize: rv[markerShadowIconSize],
                    shadowAnchor: rv[markerShadowIconAnchor],

                }) : DefaultIcon;
                const latitudeVal = rv[latitude];
                const longitudeVal = rv[longitude];
                if(latitudeVal === undefined && longitudeVal === undefined) {
                    return <></>;
                }
                return <Marker icon={icon} position={[latitudeVal, longitudeVal]}>
                    {
                        rv[tooltip] &&
                        <Popup offset={L.point(0, -10)}>
                            <div style={{display: 'flex'}}>
                                <DOMContent elements={rv[tooltip]}></DOMContent>
                            </div>
                        </Popup>
                    }
                </Marker>;
            })}
        </MapContainer>;
        return <WithMaxButton content={content}/>;
    }
}

SparqlResultMapMarkers.propTypes = {
    diagramSettings: PropTypes.any,
    data: PropTypes.any,
};

export default withStyles({}, {withTheme: true})(SparqlResultMapMarkers);
