import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import {Tooltip} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {MapOutlined} from "@material-ui/icons";
import {MapContainer} from "react-leaflet";
import {getValuesObject} from "../SearchResultItem";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import {AttributionTile, ClickableMarker} from "./mapUtil";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const styles = {
    dialogPaper: {
        minHeight: '80vh'
    },
};

class MapMarkerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            vo :{}
        }
    }

    componentDidMount() {
        let {resource, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology).then(o => {
            this.setState({vo : o});
        });


    }

    renderDeveloperModeDialog = () => {
        let {open, vo} = this.state;
        let {classes} = this.props;
        return open && <Dialog
            aria-labelledby="Map View"
            open={open}
            fullWidth={true}
            datatest={'mapMarkerDialog'}
            classes={{ paper: classes.dialogPaper }}
            maxWidth={"lg"}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={'Map View'}/></DialogTitle>
            <DialogContent>
                <div style={{minHeight : 'calc(80vh - 124px)', height : 'calc(80vh - 124px)', width: '100%'}}>
                {
                    vo.latitude !== undefined && vo.longitude !== undefined &&
                    <MapContainer
                        style={{height: '100%', width: '100%'}}
                        center={[vo.latitude, vo.longitude]}
                        zoom={13}
                    >
                        <AttributionTile></AttributionTile>
                        <ClickableMarker resourceVO={vo}/>
                    </MapContainer>
                }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                >Close</Button>
            </DialogActions>
        </Dialog>;
    }

    render() {
        let {vo} = this.state;
        let {latitude, longitude} = vo;
        if(latitude === undefined || longitude === undefined) {
            return <></>;
        }

        return <>
            <Tooltip title={'View Map'}>
                <IconButton datatest={'mapDialogViewButton'} onClick={() => this.setState({open: true})} size={'small'}>
                    <MapOutlined/>
                </IconButton>
            </Tooltip>
            {this.renderDeveloperModeDialog()}
        </>;
    }
}

MapMarkerDialog.propTypes = {
    resource: PropTypes.object,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    location: PropTypes.object,
    settings: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
};

export default withStyles(styles, {withTheme: true})(MapMarkerDialog);
