import React from "react";
import {getPropertyValue} from "../mermaid/MermaidWrapper";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {AttributionTile, getJSON, LEFT, RIGHT, WithMaxButton} from "./mapUtil";
import {MapContainer, Marker, Popup} from "react-leaflet";
import {toArray} from "../../../components/util";
import InstructionForAction from "../../../components/InstructionForAction";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import LeafletBezier from "./LeafletBezier";
import DOMContent from "../DOMContent";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const latitudeLongitudePairs = 'latitudeLongitudePairs';
export const pathColour = 'pathColour';
export const pathWeight = 'pathWeight';
export const pathDashArray = 'pathDashArray';
export const curveDepth = 'curveDepth';
export const curveBendDirection = 'curveBendDirection';
export const padding = 'padding';
export const markerLocation = 'markerLocation';
const markerTooltip = 'markerTooltip';
const markerIconURL = 'markerIconURL';
const markerIconSize = 'markerIconSize';
const markerIconAnchor = 'markerIconAnchor';
const markerShadowIconURL = 'markerShadowIconURL';
const markerShadowIconSize = 'markerShadowIconSize';
const markerShadowIconAnchor = 'markerShadowIconAnchor';

export const MAP_PATH_SETTINGS = [
    {label : "Latitude Longitude Pairs List", stateKey : latitudeLongitudePairs, helpText: 'Value should be a valid JSON array of latitude/longitude pairs e.g. [-25.2744, 133.7751, 51.4680  -0.454295]'},
    {label : "Path Colour", stateKey : pathColour},
    {label : "Path Weight", stateKey : pathWeight},
    {label : "Path Dash Array", stateKey : pathDashArray},
    {label : "Path Curve Depth", stateKey : curveDepth},
    {label : "Path Curve Bend Direction", stateKey : curveBendDirection, helpText: `Value can be '${LEFT}' or '${RIGHT}' `},
    {label : "Padding Around Paths", stateKey : padding, helpText: 'Value should be a valid JSON array e.g. [50, 50]'},
    {label : "Marker Location", stateKey : markerLocation, helpText: 'Value should be an index(first pair index is value is 0) from latitudeLongitudePairs or a string e.g. first, last or none'},
    {label : "Marker Tooltip", stateKey : markerTooltip, helpText: 'Can be HTML.'},
    {label : "Marker Icon URL", stateKey : markerIconURL},
    {label : "Marker Icon Size Array", stateKey : markerIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "Marker Icon Anchor Array", stateKey : markerIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},
    {label : "Marker Shadow Icon URL", stateKey : markerShadowIconURL},
    {label : "Marker Shadow Icon Size Array", stateKey : markerShadowIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "Marker Shadow Icon Anchor Array", stateKey : markerShadowIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},

];


class SparqlResultMapPath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.load().then(() => {

        });
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;

        let bindings = data?.["results"]?.["bindings"];
        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }

        let markers = [];
        resolvedValues.forEach(p => {
            const latLngArray = getJSON(p[latitudeLongitudePairs], []);
            const pairs = latLngArray.length / 2;
            for(let i=0; i< pairs ; i++) {
                let counter = i * 2;
                let lat = Number(latLngArray[counter]);
                let lng = Number(latLngArray[counter+1]);
                if(isNaN(lat) || isNaN(lng)) {
                    return;
                }
                markers.push(L.latLng(lat, lng));
            }
        });
        const latLngBounds = toArray(markers).length > 0 ? L.latLngBounds(markers) : undefined;
        this.setState({bounds : latLngBounds, resolvedValues });
    }

    getMarker = (rv) => {
        const markerLocationValue = rv[markerLocation];

        if(markerLocationValue) {
            const toLowerCase = markerLocationValue.toLowerCase();
            if(toLowerCase === 'none') {
                return <></>;
            }

            const latLngArray = getJSON(rv[latitudeLongitudePairs], []);
            const pairs = latLngArray.length / 2;
            let pairIndex;
            if(toLowerCase === 'first') {
                pairIndex = 0;
            } else if(toLowerCase === 'last') {
                pairIndex = pairs - 1;
            } else {
                pairIndex = Number(toLowerCase);
            }
            if(isNaN(pairIndex)) {
                return <></>;
            }
            const lat = Number(latLngArray[pairIndex * 2]);
            const lng = Number(latLngArray[pairIndex * 2 +1]);
            if(isNaN(lat) || isNaN(lng)) {
                return <></>;
            }
            const icon = rv[markerIconURL] ? new L.icon({
                iconUrl:  rv[markerIconURL],
                iconSize: getJSON(rv[markerIconSize], [24, 24]),
                iconAnchor: getJSON(rv[markerIconAnchor], [12, 24]),
                shadowUrl: rv[markerShadowIconURL],
                shadowSize: rv[markerShadowIconSize],
                shadowAnchor: rv[markerShadowIconAnchor],
            }) : DefaultIcon;

            return <Marker icon={icon} position={[lat, lng]}>
                {
                    rv[markerTooltip] &&
                    <Popup offset={L.point(0, -10)}>
                        <div style={{display: 'flex'}}>
                            <DOMContent elements={rv[markerTooltip]}></DOMContent>
                        </div>
                    </Popup>
                }
            </Marker>
        }
    }

    render() {
        let {bounds, resolvedValues, open} = this.state;
        if(toArray(resolvedValues).length === 0) {
            return <>
                <InstructionForAction text={
                    'No results found.'
                }/>
            </>;
        }
        let paddingObj = resolvedValues.find(rv => rv[padding]);

        const content = <MapContainer
            bounds={bounds}
            style={{height: open ? "calc(80vh - 124px)" : "100%", zIndex: 0}}
            ref={(ref) => {
                this.mapRef = ref;
            }}
            boundsOptions={{padding: getJSON(paddingObj?.[padding], [50, 50])}}
        >
            <AttributionTile></AttributionTile>
            {resolvedValues.map(rv => {
                let path = [];
                const latLngArray = getJSON(rv[latitudeLongitudePairs], []);
                const pairs = latLngArray.length / 2;
                for(let i=0; i< pairs ; i++) {
                    let counter = i * 2;
                    let lat = Number(latLngArray[counter]);
                    let lng = Number(latLngArray[counter+1]);
                    if(isNaN(lat) || isNaN(lng)) {
                        return;
                    }

                    path.push( { lat: lat, lng: lng, deep : rv[curveDepth], slide: rv[curveBendDirection]});
                }

                return <>
                    {this.getMarker(rv)}
                    <LeafletBezier
                        path={[path]}
                        options={{
                            animate : false,
                            color : rv[pathColour],
                            weight : rv[pathWeight] ,
                            dashArray : rv[pathDashArray]
                        }}
                    />
                </>;
            })}
        </MapContainer>;
        return <WithMaxButton content={content}></WithMaxButton> ;
    }
}

SparqlResultMapPath.propTypes = {
    diagramSettings: PropTypes.any,
    data: PropTypes.any,
};

export default withStyles({}, {withTheme: true})(SparqlResultMapPath);
