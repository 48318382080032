import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {getBrowseLanguageCode, getResourceId, getSearchResult, isEmptyArray} from "../../components/util";
import {ID} from "../../Constants";
import {Grid} from "@material-ui/core";
import SearchResultItem from "./SearchResultItem";
import InstructionForAction from "../../components/InstructionForAction";
import {getUiLabelTranslation, UI_LABELS_NO_RESULTS} from "./UILabel";
import {VIEW_MODE_LIST, VIEW_MODE_MAP} from "./Workspace";
import MapMarkers from "./map/MapMarkers";
import {reactKeyForMap} from "./map/mapUtil";


class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {location, containerType, onItemFocus, graphViewProvider, searchResult, browseLanguage,settings, aliasesMap, aliasesToIRIMap, ontology, viewMode, configurations} = this.props;
        let searchResultResources = getSearchResult(searchResult);
        let langCode = getBrowseLanguageCode(browseLanguage);
        let keyToUse = reactKeyForMap(searchResultResources);

        return <>
            {
                isEmptyArray(searchResultResources) &&
                <Grid item xs={12}>
                    <InstructionForAction text={
                        getUiLabelTranslation(settings, UI_LABELS_NO_RESULTS, browseLanguage, 'No results found.')
                    }/>
                </Grid>
            }
            {
                viewMode === VIEW_MODE_MAP ?
                    <div datatest={'mapView'} key={keyToUse} style={{height :'600px', width :'100%'}}>
                        <MapMarkers
                            resources={searchResultResources}
                            location={location}
                            browseLanguage={browseLanguage}
                            settings={settings}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            configurations={configurations}
                            ontology={ontology}
                        />
                    </div>
                    : searchResultResources.map((r, index) => {
                    const searchResultItem = <SearchResultItem
                        graphViewProvider={graphViewProvider}
                        location={location}
                        browseLanguage={browseLanguage}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        resource={r}
                        ontology={ontology}
                        onItemFocus={onItemFocus}
                        viewMode={viewMode}
                        viewProperties={[]}
                        configurations={configurations}
                    />;
                    if(containerType === 'div') {
                        return <div>{searchResultItem}</div>;
                    }
                    return <Grid key={r[ID] + langCode + index} item xs={viewMode === VIEW_MODE_LIST && 12}>
                        {searchResultItem}
                    </Grid>;
                })
            }
        </>;
    }
}

SearchResult.propTypes = {
    graphViewProvider: PropTypes.any,
    configurations: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    searchResult: PropTypes.object,
    onItemFocus : PropTypes.func,
    viewMode: PropTypes.string,
    containerType: PropTypes.string,

};

export default withStyles(styles, {withTheme:true})(SearchResult);
