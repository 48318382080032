import React from "react";
import {getPropertyValue} from "../mermaid/MermaidWrapper";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {getJSON, LEFT, RIGHT} from "./mapUtil";
import {toArray} from "../../../components/util";
import InstructionForAction from "../../../components/InstructionForAction";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import FlightPath from "./FlightPath";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const startTooltip = 'startTooltip';
const startLatitude = 'startLatitude';
const startLongitude = 'startLongitude';
const startMarkerIconURL = 'startMarkerIconURL';
const startMarkerIconSize = 'startMarkerIconSize';
const startMarkerIconAnchor = 'startMarkerIconAnchor';
const startMarkerShadowIconURL = 'startMarkerShadowIconURL';
const startMarkerShadowIconSize = 'startMarkerShadowIconSize';
const startMarkerShadowIconAnchor = 'startMarkerShadowIconAnchor';
const endTooltip = 'endTooltip';
const endLatitude = 'endLatitude';
const endLongitude = 'endLongitude';
const endMarkerIconURL = 'endMarkerIconURL';
const endMarkerIconSize = 'endMarkerIconSize';
const endMarkerIconAnchor = 'endMarkerIconAnchor';
const endMarkerShadowIconURL = 'endMarkerShadowIconURL';
const endMarkerShadowIconSize = 'endMarkerShadowIconSize';
const endMarkerShadowIconAnchor = 'endMarkerShadowIconAnchor';

export const pathColour = 'pathColour';
export const pathWeight = 'pathWeight';
export const pathDashArray = 'pathDashArray';
export const curveDepth = 'curveDepth';
export const curveBendDirection = 'curveBendDirection';
const padding = 'padding';
const animation = 'animation';
const animationIcon = 'animationIcon';

export const MAP_FLIGHT_PATH_SETTINGS = [
    {label : "Start Latitude", stateKey : startLatitude},
    {label : "Start Longitude", stateKey : startLongitude},
    {label : "Start Tooltip HTML Content", stateKey : startTooltip},
    {label : "Start Marker Icon URL", stateKey : startMarkerIconURL},
    {label : "Start Marker Icon Size Array", stateKey : startMarkerIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "Start Marker Icon Anchor Array", stateKey : startMarkerIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},
    {label : "Start Marker Shadow Icon URL", stateKey : startMarkerShadowIconURL},
    {label : "Start Marker Shadow Icon Size Array", stateKey : startMarkerShadowIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "Start Marker Shadow Icon Anchor Array", stateKey : startMarkerShadowIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},
    {label : "End Latitude", stateKey : endLatitude},
    {label : "End Longitude", stateKey : endLongitude},
    {label : "End Tooltip HTML Content", stateKey : endTooltip},
    {label : "End Marker Icon URL", stateKey : endMarkerIconURL},
    {label : "End Marker Icon Size Array", stateKey : endMarkerIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "End Marker Icon Anchor Array", stateKey : endMarkerIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},
    {label : "End Marker Shadow Icon URL", stateKey : endMarkerShadowIconURL},
    {label : "End Marker Shadow Icon Size Array", stateKey : endMarkerShadowIconSize, helpText: 'Value should be a valid JSON array e.g. [25, 41]'},
    {label : "End Marker Shadow Icon Anchor Array", stateKey : endMarkerShadowIconAnchor, helpText: 'Value should be a valid JSON array e.g. [12, 41]'},
    {label : "Path Colour", stateKey : pathColour},
    {label : "Path Weight", stateKey : pathWeight},
    {label : "Path Dash Array", stateKey : pathDashArray},
    {label : "Path Curve Depth", stateKey : curveDepth},
    {label : "Path Curve Bend Direction", stateKey : curveBendDirection, helpText: `Value can be '${LEFT}' or '${RIGHT}' `},
    {label : "Padding Around Paths", stateKey : padding, helpText: 'Value should be a valid JSON array e.g. [50, 50]'},
    {label : "Animation", stateKey : animation, helpText: 'Value should be a valid true or false'},
    {label : "Animation Icon", stateKey : animationIcon},
];


class SparqlResultFlightPaths extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.load().then(() => {

        });
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;

        let bindings = data?.["results"]?.["bindings"];
        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }
        let paths = [];
        resolvedValues.forEach(rv => {
            const startLatitudeVal = rv[startLatitude];
            const startLongitudeVal = rv[startLongitude];
            const endLatitudeVal = rv[endLatitude];
            const endLongitudeVal = rv[endLongitude];
            let startObject = paths.find(p => p.start.latitude === startLatitudeVal && p.start.longitude === startLongitudeVal);
            if(startObject) {
                const endObject = startObject.destinations.find(d => d.latitude === endLatitudeVal && d.longitude === endLongitudeVal);
                if(!endObject) {
                    startObject.destinations.push({
                        latitude : endLatitudeVal,
                        longitude : endLongitudeVal
                    })
                }
            } else {
                let obj = {
                    start: {
                        latitude: startLatitudeVal,
                        longitude: startLongitudeVal,
                        tooltip : rv[startTooltip],
                        [curveDepth] : rv[curveDepth],
                        [curveBendDirection] : rv[curveBendDirection],
                        markerIconURL : rv[startMarkerIconURL],
                        markerIconSize : getJSON(rv[startMarkerIconSize], undefined),
                        markerIconAnchor : getJSON(rv[startMarkerIconAnchor], undefined),
                        markerShadowIconURL : rv[startMarkerShadowIconURL],
                        markerShadowIconSize : rv[startMarkerShadowIconSize],
                        markerShadowIconAnchor : rv[startMarkerShadowIconAnchor],
                        pathColour : rv[pathColour],
                        pathWeight : rv[pathWeight],
                        pathDashArray : rv[pathDashArray],
                        animation : rv[animation],
                        animationIcon : rv[animationIcon]
                    },
                    destinations: [{
                        latitude : endLatitudeVal,
                        longitude : endLongitudeVal,
                        tooltip : rv[endTooltip],
                        markerIconURL : rv[endMarkerIconURL],
                        markerIconSize: getJSON(rv[endMarkerIconSize], undefined),
                        markerIconAnchor : getJSON(rv[endMarkerIconAnchor], undefined),
                        markerShadowIconURL : rv[endMarkerShadowIconURL],
                        markerShadowIconSize : rv[endMarkerShadowIconSize],
                        markerShadowIconAnchor : rv[endMarkerShadowIconAnchor]
                    }]
                }
                paths.push(obj);
            }

        })

        this.setState({ resolvedValues, paths });
    }

    render() {
        let {bounds, resolvedValues, open, paths} = this.state;
        let {theme, settings, aliasesToIRIMap, ontology, browseLanguage} = this.props;
        if(toArray(paths).length === 0) {
            return <>
                <InstructionForAction text={
                    'No results found.'
                }/>
            </>;
        }
        let paddingVal = resolvedValues.find(rv => rv[padding]);

        return <FlightPath
            theme={theme}
            paths={paths}
            boundsOptions={{padding: getJSON(paddingVal?.[padding], [50, 50])}}
            containerHeight={"100%"}
            settings={settings}
            aliasesToIRIMap={aliasesToIRIMap}
            ontology={ontology}
            browseLanguage={browseLanguage}
        />;
    }
}

SparqlResultFlightPaths.propTypes = {
    diagramSettings: PropTypes.any,
    data: PropTypes.any,
    settings: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any

};

export default withStyles({}, {withTheme: true})(SparqlResultFlightPaths);
