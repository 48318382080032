import {Marker, Popup, TileLayer} from "react-leaflet";
import L from "leaflet";
import React, {useState} from "react";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import IconButton from "@material-ui/core/IconButton";
import {FullscreenExitOutlined, FullscreenOutlined, MoreVertOutlined} from "@material-ui/icons";
import H4Title from "../../../components/H4Title";
import {centerVertically, getResourceId} from "../../../components/util";
import {ALIAS_SH_PATH} from "../../../Constants";
import {PROPERTY_VALUE_VIEW_SETTINGS} from "../DataViewSetup";
import DOMContent from "../DOMContent";
import {Paper, useTheme} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

export const PATH_BEND_DEPTH = "PATH_BEND_DEPTH";
export const PATH_COLOR = "PATH_COLOR";
export const PATH_WEIGHT = "PATH_WEIGHT";
export const PATH_DASH_ARRAY = "PATH_DASH_ARRAY";
export const PATH_BEND_DIRECTION = "PATH_BEND_DIRECTION";
export const LEFT = "Left";
export const RIGHT = "Right";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

export function ClickableMarker({resourceVO, icon, onMoreClick, tooltipProvider}) {
    const lat = Number(resourceVO.latitude);
    const lng = Number(resourceVO.longitude);
    if(isNaN(lat) === false && isNaN(lng) === false) {
        const iconToUse = icon||DefaultIcon;
        return <Marker title={resourceVO.title} icon={iconToUse} position={[lat, lng]}>
            <Popup offset={L.point(0, -10)}>
                {
                    tooltipProvider ? <>{tooltipProvider(resourceVO)}</> :
                    <div style={{display: 'flex'}}>
                        {centerVertically(<H4Title title={resourceVO.title}/>, {flexGrow: '1'})}
                        {onMoreClick &&
                            <IconButton datatest={"markerMore"} onClick={onMoreClick} size={"small"}><MoreVertOutlined/></IconButton>}
                    </div>
                }
            </Popup>
        </Marker>;
    }
    return <></>;
}

export function SimpleClickableMarker({latitude, longitude, icon, tooltip}) {
    const lat = Number(latitude);
    const lng = Number(longitude);
    if(isNaN(lat) === false && isNaN(lng) === false) {
        return <Marker icon={icon} position={[lat, lng]}>
            {
                tooltip &&
                <Popup offset={L.point(0, -10)}>
                    <div style={{display: 'flex'}}>
                        <DOMContent elements={tooltip}></DOMContent>
                    </div>
                </Popup>
            }
        </Marker>;
    }
    return <></>;
}

export function AttributionTile() {
    return <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    />;
}

function getPropertyIRI(settings, aliasesToIRIMap, shapeProperty) {
    let propertyKey = shapeProperty?.[ALIAS_SH_PATH] || shapeProperty;
    let propertyIRI = aliasesToIRIMap?.[propertyKey] || propertyKey;
    return propertyIRI;
}

export function getPathBendDepth(settings, aliasesToIRIMap, shapeProperty) {
    let propertyIRI = getPropertyIRI(settings, aliasesToIRIMap, shapeProperty);
    if(propertyIRI) {
        const value = settings.labelProperties?.[propertyIRI]?.[PROPERTY_VALUE_VIEW_SETTINGS]?.[PATH_BEND_DEPTH];
        if(value <= 2) {
            return "1";
        }
        const number = Number(value);
        if(isNaN(number)) {
            return undefined;
        }
        return 10 - (number > 7.9 ? 7.9 : number)  ;
    }
    return undefined;
}

export function getPathBendDirection(settings, aliasesToIRIMap, shapeProperty) {
    let propertyIRI = getPropertyIRI(settings, aliasesToIRIMap, shapeProperty);
    let value = propertyIRI
        ? settings.labelProperties?.[propertyIRI]?.[PROPERTY_VALUE_VIEW_SETTINGS]?.[PATH_BEND_DIRECTION]
        : undefined;
    if(value === LEFT) {
        return "LEFT_ROUND";
    }
    if(value === RIGHT) {
        return "RIGHT_ROUND";
    }
}

function getSettingValue(settings, aliasesToIRIMap, shapeProperty, key) {
    let propertyIRI = getPropertyIRI(settings, aliasesToIRIMap, shapeProperty);
    let value = propertyIRI
        ? settings.labelProperties?.[propertyIRI]?.[PROPERTY_VALUE_VIEW_SETTINGS]?.[key]
        : undefined;
    return value;
}

export function getPathColor(settings, aliasesToIRIMap, shapeProperty) {
    return getSettingValue(settings, aliasesToIRIMap, shapeProperty, PATH_COLOR);
}

export function getPathWeight(settings, aliasesToIRIMap, shapeProperty) {
    return getSettingValue(settings, aliasesToIRIMap, shapeProperty, PATH_WEIGHT);
}

export function getPathDashArray(settings, aliasesToIRIMap, shapeProperty) {
    return getSettingValue(settings, aliasesToIRIMap, shapeProperty, PATH_DASH_ARRAY);
}

export function getJSON(stringToParse, defaultToReturn) {
    try {
        if(stringToParse) {
            return JSON.parse(stringToParse);
        } else {
            return defaultToReturn;
        }
    } catch (e) {
        return defaultToReturn;
    }
}

export function getMaximizeButton(onClick, open, theme) {
    return <Paper style={{
        border: "2px solid",
        borderColor: theme.palette.grey.level2,
        position: 'absolute',
        right: '10px',
        top: '10px',
        zIndex: '1000'
    }} elevation={0}>
        <IconButton
            datatest={'maximizeButton'}
            size={'small'}
            onClick={() => onClick(!open)}
        >{open ? <FullscreenExitOutlined/> : <FullscreenOutlined/>}</IconButton>

    </Paper>;
}

export function WithMaxButton({content, containerHeight = "100%"}) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    let allContent = <div style={{height: open ? "100%" : containerHeight, position: "relative"}}>
        {content}
        {getMaximizeButton(setOpen, open, theme)}
    </div>;
    return open ? <Dialog
        aria-labelledby="Map View"
        open={open}
        fullWidth={true}
        fullScreen={true}
        datatest={'mapMarkerDialog'}
    >
        <DialogContent style={{padding : '0px'}}>
            <div style={{minHeight: '100%', height: '100%', width: '100%'}}>
                {allContent}
            </div>
        </DialogContent>
    </Dialog> : allContent
}

export function reactKeyForMap(resources) {
    const resourceIds = resources.map(rv => getResourceId(rv)).filter(v => v);
    const length = resourceIds.length;
    let keyToUse = length > 0 ? resourceIds.slice(-1)[0] : "";
    return keyToUse+"-"+length;
}
