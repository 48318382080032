import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {MapContainer} from "react-leaflet";
import {getValuesObject} from "../SearchResultItem";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import {AttributionTile, ClickableMarker, getMaximizeButton, reactKeyForMap} from "./mapUtil";
import {getHeader as getAccordianHeader} from "../ResultAccordion";
import {fade} from "@material-ui/core/styles/colorManipulator";
import Tree from "../Tree";
import {navigateTo} from "../Workspace";
import {getResourceId} from "../../../components/util";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FieldContainer from "../../../components/FieldContainer";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {getUiLabelTranslation, UI_LABELS_NO_MAP_LAT_LNG_DATA} from "../UILabel";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const styles = {
    dialogPaper: {
        minHeight: '80vh'
    },
};


class MapMarkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
        let {resources, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let all = resources.map(r => getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology));
        Promise.all(all).then(all => {
            this.setState({resourcesVOs : all})
        })
    }

    handleOnMoreClick = (rvo) => {
        let {resourcesVOs, rightResource} = this.state;
        let {resources} = this.props;
        const resourceId = getResourceId(rvo);
        const resource = resources.find(r => getResourceId(r) === resourceId);
        rvo.backingObject = resource;
        this.setState({rightResourceVO : rvo}, () => {
            let center = L.latLng(Number(rvo.latitude), Number(rvo.longitude));
            this.mapRef.invalidateSize();
        });
    }

    renderDeveloperModeDialog = () => {
        let {resourcesVOs, rightResourceVO, open} = this.state;
        let { location, configurations, settings, aliasesMap, aliasesToIRIMap, ontology, browseLanguage} = this.props;

        let {classes, theme} = this.props;
        if(!resourcesVOs) {
            return <></>;
        }
        let markers = resourcesVOs.map(rv => {
            const lat = Number(rv.latitude);
            const lng = Number(rv.longitude);

            if(lat && lng && !isNaN(lat) && !isNaN(lng)) {
                return L.latLng(lat, lng);
            }
            return undefined;
        }).filter(v => v);
        const latLngBounds = markers.length > 0 ? L.latLngBounds(markers) : undefined;
        let keyToUse = reactKeyForMap(resourcesVOs);
        const content = <>
            {latLngBounds === undefined && <div style={{textAlign : 'center'}}>
                {getUiLabelTranslation(settings, UI_LABELS_NO_MAP_LAT_LNG_DATA, browseLanguage, UI_LABELS_NO_MAP_LAT_LNG_DATA)}</div>
            }

            <div key={keyToUse} style={{height: '100%', width: '100%', display : 'flex'}}>
            <div style={{height: '100%', flexGrow: '1', position: 'relative'}}>
                {getMaximizeButton(() => {this.setState({open : !open})}, open, theme)}
                {
                        <MapContainer
                            style={{height: '100%', width: '100%'}}
                            bounds={latLngBounds}
                            ref={(ref) => {
                                this.mapRef = ref;
                            }}
                        >
                            <AttributionTile></AttributionTile>
                            {resourcesVOs.map(rv => {
                                return <ClickableMarker resourceVO={rv} onMoreClick={() => this.handleOnMoreClick(rv)}/>;
                            })}
                        </MapContainer>

                }
            </div>
            {
                rightResourceVO &&
                <FieldContainer datatest={"markerDetails"} key={getResourceId(rightResourceVO)}  style={{
                    padding : '0px',
                    backgroundColor: theme.palette.grey.main,
                    height: '100%',
                    maxWidth: `${400 - 36}px`,
                }}>
                    <div style={{display: 'flex', padding : '4px'}}>
                        <div style={{flexGrow: '1'}}></div>
                        <Tooltip title={'Close'}>
                            <IconButton datatest={'closeTreeButton'} size={'small'} onClick={() => {
                                this.setState({rightResourceVO: undefined}, () => {
                                    this.mapRef.invalidateSize();
                                })
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{
                        height: "calc(100% - 56px)",
                        overflowY: 'auto',
                        overflowX: 'auto',
                        borderRadius: '4px',
                        margin: '0px 8px 8px 8px',
                        paddingBottom: '8px',
                        backgroundColor: theme.palette.white.main
                    }}>
                        {
                            getAccordianHeader(
                                rightResourceVO.title,
                                rightResourceVO.backingObject,
                                rightResourceVO.thumbnail,
                                {borderBottom: '1px solid', borderBottomColor: fade(theme.palette.secondary.main, 0.5)},
                                undefined
                            )
                        }
                        <Tree
                            location={location}
                            ontology={ontology}
                            configurations={configurations}
                            aliasesToIRIMap={aliasesToIRIMap}
                            aliasesMap={aliasesMap}
                            settings={settings}
                            resource={rightResourceVO.backingObject}
                            browseLanguage={browseLanguage}
                            theme={theme}
                            viewProperties={[]}
                            onConceptClick={(foundResource, details) => {
                                this.setState({rightResourceVO: undefined});
                                return navigateTo(foundResource, details, location);
                            }}
                            customizations={{propertyPaneFullWidth : true}}
                        />
                    </div>
                </FieldContainer>
            }
        </div> </>;
        return open ? <Dialog
            aria-labelledby="Map View"
            open={open}
            fullWidth={true}
            fullScreen={true}
            datatest={'mapMarkerDialog'}
        >
            <DialogContent style={{padding : '0px'}}>
                <div style={{minHeight: '100%', height: '100%', width: '100%'}}>
                    {content}
                </div>
            </DialogContent>
        </Dialog> : content;
    }

    render() {


        return <>
            {this.renderDeveloperModeDialog()}
        </>;
    }
}

MapMarkers.propTypes = {
    resources: PropTypes.object,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    location: PropTypes.object,
    settings: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
};

export default withStyles(styles, {withTheme: true})(MapMarkers);
