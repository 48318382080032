import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {Grid} from "@material-ui/core";
import {fieldContainerDefaultBackgroundColor} from "../../components/FieldContainer";
import H3Title from "../../components/H3Title";
import {isFeedbackEnabled} from "./BasicSetup";
import {centerVertically, GetEditableClassNames, getPropertyName} from "../../components/util";
import FeedbackWidget from "./FeedbackWidget";
import AddFeedbackDialog from "./AddFeedbackDialog";
import {WithAdminEdit} from "./WithAdminEdit";
import {SETTINGS_EDIT_PROPERTY} from "./Workspace";

class SimpleViewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus : false
        }
    }

    handleFocusOff = () => {
        let {focus} = this.state;
        if(focus === true) {
            this.setState({focus: false});
        }
    }

    handleFocusOn = () => {
        let {focus} = this.state;
        if(focus === false) {
            this.setState({focus: true});
        }
    }

    render() {
        let {theme, index, propertyKey, resource, customizations, settings, location, ontology, aliasesMap, aliasesToIRIMap, configurations, nodeSubTree, browseLanguage, context} = this.props;
        let {focus, addFeedback} = this.state;
        const propertyName = getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);

        const showFeedback = focus && isFeedbackEnabled(settings) && customizations?.feedback !== false;
        return <Grid datatest={propertyKey} key={propertyKey + index} container direction={'column'} item sm={12} md={ customizations?.propertyPaneFullWidth === true ? 12: 6}>
            <div
                onFocus={this.handleFocusOn}
                onBlur={this.handleFocusOff}
                onMouseEnter={this.handleFocusOn}
                onMouseLeave={this.handleFocusOff}
                style={{
                    borderRadius: "4px",
                    padding: "12px",
                    backgroundColor: theme.palette.grey.main,
                    flexGrow : 1,
                    maxWidth : 'calc(100% - 24px)'
                }}
                tabIndex={0}
            >
                <div datatest={'cardTitle'+"-"+propertyKey} style={{display : 'flex', marginBottom : '8px'}}>
                    {centerVertically(
                        <H3Title style={{textTransform : 'capitalize'}} className={GetEditableClassNames(SETTINGS_EDIT_PROPERTY, propertyKey)}>
                            <WithAdminEdit data={{action : SETTINGS_EDIT_PROPERTY, id: propertyKey, propertyKey, propertyName}}/>
                            {propertyName}
                        </H3Title>
                        , {overflow :'auto'})}
                    <div style={{flexGrow : '1'}}></div>
                    {
                         showFeedback ? centerVertically(
                            <FeedbackWidget
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    this.setState({
                                        addFeedback: true,
                                        feedBackContext : context
                                    });
                                }}
                                settings={settings}
                                location={location}
                                aliasesToIRIMap={aliasesToIRIMap}
                                ontology={ontology}
                                aliasesMap={aliasesMap}
                                browseLanguage={browseLanguage}
                                configurations={configurations}
                            />, {marginLeft: '8px'}
                        ) : <div style={{minHeight : '30px'}}></div>
                    }
                    {
                        addFeedback && <AddFeedbackDialog
                            location={location}
                            context={ context}
                            parentObject={resource}
                            propertyKey={propertyKey}
                            shapeProperty={context.shapeProperty}
                            nodeValue={{}}
                            ontology={ontology}
                            browseLanguage={browseLanguage}
                            aliasesToIRIMap={aliasesToIRIMap}
                            aliasesMap={aliasesMap}
                            settings={settings}
                            onClose={() => this.setState({focus: false, addFeedback: undefined})}
                            onSaveSuccess={() => {
                                this.setState({addFeedback: undefined})
                            }}
                        />
                    }
                </div>
                {
                    nodeSubTree
                }
            </div>
        </Grid>;

    }
}

SimpleViewCard.propTypes = {
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    location: PropTypes.object,
    settings: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    resource: PropTypes.object,
    propertyKey: PropTypes.any,
    index: PropTypes.any,
    customizations: PropTypes.any,
    context: PropTypes.any,
    nodeSubTree: PropTypes.any,
};

export default withStyles(styles, {withTheme: true})(SimpleViewCard);
