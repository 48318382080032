import React, {Component} from "react";
import {getValuesObject} from "./SearchResultItem";
import {DeveloperViewComp, FilterPropertiesComp, getHeader} from "./ResultAccordion";
import {fade} from "@material-ui/core/styles/colorManipulator";
import IconButton from "@material-ui/core/IconButton";
import {ExpandLessOutlined, ExpandMoreOutlined} from "@material-ui/icons";
import {Checkbox} from "@material-ui/core";
import H3Title from "../../components/H3Title";
import {getBrowseLanguageCode} from "../../components/util";
import Tree from "./Tree";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withPermissions} from "../../service/permission-service";
import {ID} from "../../Constants";
import MapMarkerDialog from "./map/MapMarkerDialog";

export function renderTreeActions(viewProperties, configurations, browseLanguage, aliasesToIRIMap, ontology, resource, graphViewProvider, settings) {
    return <div style={{paddingLeft: '8px'}}>
        <div datatest={'filterProperties'} style={{marginTop: '8px'}}>
            <FilterPropertiesComp
                viewProperties={viewProperties}
                configurations={configurations}
                browseLanguage={browseLanguage}
                aliasesToIRIMap={aliasesToIRIMap}
                ontology={ontology}
                onChange={() => this.setState({})}
            />
        </div>
        <div style={{marginTop: '8px'}}>
            <DeveloperViewComp resource={resource}/>
        </div>
        <div style={{marginTop: '8px'}}>
            {
                resource && settings &&
                <MapMarkerDialog
                    resource={resource}
                    configurations={configurations}
                    browseLanguage={browseLanguage}
                    aliasesToIRIMap={aliasesToIRIMap}
                    ontology={ontology}
                    settings={settings}
                />
            }
        </div>

        {
            graphViewProvider &&
            <div style={{marginTop: '8px'}}>
                {graphViewProvider(resource[ID])}
            </div>
        }
    </div>;
}

class ListResultItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            thumbnail: undefined,
            footer: <></>,
            otherProperties: [],
            expanded : false
        }
    }

    componentDidMount() {
        let {resource, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology).then(o => {
            this.setState({...o});
        });
    }

    getStartComponent = (disabled, selected, onSelectChange) => {
        let {disableSelection} = this.props;
        if (disableSelection) {
            return undefined;
        }
        return () => <Checkbox
            size={'small'}
            disabled={disabled}
            style={{marginRight: '16px'}}
            name={'selected'}
            checked={selected}
            onChange={(event) => {
                const {target: {checked}} = event
                this.setState({selected: checked});
                onSelectChange && onSelectChange(checked);
            }}
            value="selected"/>;
    }

    render() {
        let {title, thumbnail, expanded, } = this.state;
        let {disabled, containerStyle, secondaryInfoProvider, hideTreeActions, viewProperties, graphViewProvider, customizations, configurations, theme, resource, onSelectChange, selected, location, settings, browseLanguage, aliasesMap, aliasesToIRIMap, ontology, hideIdInTitle} = this.props;
        let langCode = getBrowseLanguageCode(browseLanguage);

        return <div id={resource[ID]} datatest={'listResultItem'} key={resource[ID]+langCode} style={{borderRadius : '4px', marginBottom : '16px', backgroundColor: theme.palette.white.main, minHeight : '112px', ...(containerStyle ? containerStyle : {}) }}>
            <div style={expanded ? {borderBottom : '1px solid', borderBottomColor : fade(theme.palette.secondary.main, 0.5)} : {}}>
            {
                getHeader(
                    title,
                    resource,
                    thumbnail,
                    undefined,
                    () => <>
                        <IconButton
                            datatest={'expandCollapseButton'}
                            size={'small'}
                            onClick={() => this.setState({expanded : !expanded})}
                        >{
                            expanded ? <ExpandLessOutlined></ExpandLessOutlined> : <ExpandMoreOutlined></ExpandMoreOutlined>
                        }</IconButton></>,
                    this.getStartComponent(disabled, selected, onSelectChange),
                    (titleToRender) => {
                        return <H3Title title={titleToRender} noWrap={true}></H3Title>
                    },
                    hideIdInTitle
                )
            }
            {
                secondaryInfoProvider && secondaryInfoProvider()
            }
            </div>
            {
                expanded === false ? <></> :
                    <div style={{display: "flex"}}>
                        {hideTreeActions ? <></> : renderTreeActions.call(this, viewProperties, configurations, browseLanguage, aliasesToIRIMap, ontology, resource, graphViewProvider, settings)}

                        <div style={{paddingBottom: '8px'}}>
                            <Tree
                                customizations={customizations}
                                location={location}
                                ontology={ontology}
                                configurations={configurations}
                                aliasesToIRIMap={aliasesToIRIMap}
                                aliasesMap={aliasesMap}
                                settings={settings}
                                resource={resource}
                                browseLanguage={browseLanguage}
                                theme={theme}
                                viewProperties={viewProperties}
                            />
                        </div>
                    </div>
            }
        </div>;
    }

}

ListResultItem.propTypes = {
    graphViewProvider: PropTypes.any,
    viewProperties: PropTypes.any,
    customizations: PropTypes.any,
    containerStyle: PropTypes.any,
    resource: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    onSelectChange: PropTypes.func,
    secondaryInfoProvider: PropTypes.func,
    disableSelection: PropTypes.bool,
    hideTreeActions: PropTypes.bool,
    hideIdInTitle: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,

};

export default  withStyles({}, {withTheme: true})(withPermissions(ListResultItem));
