import React, {useState} from "react";
import "leaflet/dist/leaflet.css";
import {MapContainer} from "react-leaflet";
import LeafletBezier from "./LeafletBezier";
import {toArray} from "../../../components/util";
import icon from 'leaflet/dist/images/marker-icon.png';
import L from "leaflet";
import {
    AttributionTile,
    ClickableMarker,
    getMaximizeButton,
    getPathBendDepth,
    getPathBendDirection,
    getPathColor,
    getPathDashArray,
    getPathWeight,
    SimpleClickableMarker
} from "./mapUtil";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {curveBendDirection, curveDepth} from "./SparqlResultFlightPaths";


let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const styles = {
    dialogPaper: {
        minHeight: '80vh'
    },
};


export function MapDialog(aliasesToIRIMap, propertyKey, shapeProperty, ontology, browseLanguage, open, content, setOpen) {

    return open && <Dialog
        aria-labelledby="Map View"
        open={open}
        fullWidth={true}
        fullScreen={true}
        datatest={'mapMarkerDialog'}
    >
        <DialogContent style={{padding : '0px'}}>
            <div style={{minHeight: '100%', height: '100%', width: '100%'}}>
                {content}
            </div>
        </DialogContent>
    </Dialog>;
}

function getMarkers(po, startIcon, endIcon, tooltipProvider) {
    const start = po.start;
    if (start?.title) {
        return <>
            <ClickableMarker resourceVO={start} icon={startIcon} tooltipProvider={tooltipProvider}/>
            {
                po.destinations.map((d, i) => {
                    return <React.Fragment key={i}>
                        <ClickableMarker
                            resourceVO={d}
                            icon={endIcon}
                            tooltipProvider={tooltipProvider}
                        />
                    </React.Fragment>;
                })
            }
        </>;
    } else {
        const startIconToUse = start.markerIconURL ? new L.icon({
            iconUrl: start.markerIconURL,
            iconSize: start.markerIconSize || [24, 24],
            iconAnchor: start.markerIconAnchor || [12, 24],
            shadowUrl: start.markerShadowIconURL,
            shadowSize: start.markerShadowIconSize,
            shadowAnchor: start.markerShadowIconAnchor,

        }) : startIcon;

        return <>
            <SimpleClickableMarker
                icon={startIconToUse}
                latitude={start.latitude}
                longitude={start.longitude}
                tooltip={start.tooltip}
            />

            {
                po.destinations.map((d, i) => {
                    const endIconToUse = d.markerIconURL ? new L.icon({
                        iconUrl: d.markerIconURL,
                        iconSize: d.markerIconSize || [24, 24],
                        iconAnchor: d.markerIconAnchor || [12, 24],
                        shadowUrl: d.markerShadowIconURL,
                        shadowSize: d.markerShadowIconSize,
                        shadowAnchor: d.markerShadowIconAnchor,
                    }) : endIcon;

                    return <React.Fragment key={i}>
                        <SimpleClickableMarker
                            icon={endIconToUse}
                            latitude={d.latitude}
                            longitude={d.longitude}
                            tooltip={d.tooltip}
                        />
                    </React.Fragment>;
                })
            }

        </>;
    }
}

export default function FlightPath({paths, containerHeight, boundsOptions={padding: [50, 50]}, theme, settings, aliasesToIRIMap, shapeProperty, propertyKey, ontology, browseLanguage, tooltipProvider}) {

    const [open, setOpen] = useState(false);
    let markers = [];
    let depth= (shapeProperty && getPathBendDepth(settings, aliasesToIRIMap, shapeProperty)) || 6;
    let bendDirection= (shapeProperty && getPathBendDirection(settings, aliasesToIRIMap, shapeProperty)) || "RIGHT_ROUND";

    toArray(paths).forEach(po => {
        let {start, destinations} = po;
        toArray(destinations).map((d, index) => {
            const startLat = Number(start.latitude);
            const startLng = Number(start.longitude);
            if(isNaN(startLng) || isNaN(startLng)) {
                return;
            }
            if(index === 0) {
                markers.push(L.latLng(startLat, startLng));
            }
            const lat = Number(d.latitude);
            const lng = Number(d.longitude);
            if(isNaN(lat) || isNaN(lng)) {
                return;
            }
            markers.push(L.latLng(lat, lng));
        })
    })

    const getPath = (po) => {
        let {start, destinations} = po;
        let path = [];
        toArray(destinations).map((d, index) => {
            const startLat = Number(start.latitude);
            const startLng = Number(start.longitude);
            const lat = Number(d.latitude);
            const lng = Number(d.longitude);
            if(isNaN(startLat) || isNaN(startLng) || isNaN(lat) || isNaN(lng)) {
                return;
            }
            path.push(
                [
                    { lat: startLat, lng: startLng, deep : d[curveDepth] || depth, slide: d[curveBendDirection] || bendDirection },
                    { lat: lat, lng: lng },
                ]
            );
        })
        return path;

    }

    let svg = `<svg fill="${theme.palette.primary.main}" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>`;
    let blob = new Blob([svg], {type: 'image/svg+xml'});
    let url = URL.createObjectURL(blob);

    const startIcon = new L.icon({
        iconUrl:  url,
        iconSize: [24, 24],
        iconAnchor: [12, 24]
    });

    let endsvg = `<svg fill="${theme.palette.secondary.main}" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>`;
    let endblob = new Blob([endsvg], {type: 'image/svg+xml'});
    let endurl = URL.createObjectURL(endblob);

    const endIcon = new L.icon({
        iconUrl:  endurl,
        iconSize: [24, 24],
        iconAnchor: [12, 24]
    });
    const latLngBounds = markers.length > 0 ? L.latLngBounds(markers) : undefined;


    const mapStyle = {height: open ? "100%" : containerHeight || "400px", zIndex: 0};
    const content = <>
        <div style={{height : open ? "100%": containerHeight, position: "relative"}}>
            {getMaximizeButton(() => setOpen(!open), open, theme)}
            <MapContainer bounds={latLngBounds} boundsOptions={boundsOptions} zoom={1} style={mapStyle}>

                <AttributionTile></AttributionTile>
                {
                    toArray(paths).map((po, index) => {
                        const start = po.start;


                        return <React.Fragment key={index}>
                            <LeafletBezier
                                path={getPath(po)}
                                options={{
                                    animate: start.animation !== undefined ? (start.animation === "true" ? true: false) : true,
                                    color: start.pathColour ? start.pathColour : getPathColor(settings, aliasesToIRIMap, shapeProperty),
                                    weight: start.pathWeight ? start.pathWeight : getPathWeight(settings, aliasesToIRIMap, shapeProperty),
                                    dashArray: start.pathDashArray ? start.pathDashArray  : getPathDashArray(settings, aliasesToIRIMap, shapeProperty),
                                    animationIcon: start.animationIcon

                                }}
                            />
                            {getMarkers(po, startIcon, endIcon, tooltipProvider)}
                        </React.Fragment>
                    })
                }
            </MapContainer>
        </div>
    </>;
    return open ? MapDialog(aliasesToIRIMap, propertyKey, shapeProperty, ontology, browseLanguage, open, content, setOpen) : content;
}