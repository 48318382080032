import React, {useState} from "react";
import "leaflet/dist/leaflet.css";
import {MapContainer} from "react-leaflet";
import LeafletBezier from "./LeafletBezier";
import icon from 'leaflet/dist/images/marker-icon.png';
import L from "leaflet";
import {
    AttributionTile,
    getPathBendDepth,
    getPathBendDirection,
    getPathColor,
    getPathDashArray,
    getPathWeight,
    WithMaxButton
} from "./mapUtil";


let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

export default function Path({latLngData, theme, settings, aliasesToIRIMap, shapeProperty, propertyKey, ontology, browseLanguage}) {
    const [open, setOpen] = useState(false);

    const path = [];
    let latLngDataMatch = [...latLngData.matchAll(/[+-]?[0-9]+([.][0-9]+)?/g)];
    let depth=getPathBendDepth(settings, aliasesToIRIMap, shapeProperty);
    let bendDirection= getPathBendDirection(settings, aliasesToIRIMap, shapeProperty);

    const pairs = latLngDataMatch.length / 2;
    for(let i=0; i< pairs ; i++) {
        let counter = i * 2;
        let lat = latLngDataMatch[counter][0];
        let lng = latLngDataMatch[counter+1][0];
        path.push({ lat: Number(lat), lng: Number(lng), deep :  depth, slide: bendDirection })
    }
    let markers = path.map(p => {
        const lat = Number(p.lat);
        const lng = Number(p.lng);

        if(lat && lng && !isNaN(lat) && !isNaN(lng)) {
            return L.latLng(lat, lng);
        }
        return undefined;
    }).filter(v => v);

    const latLngBounds = markers.length > 0 ? L.latLngBounds(markers) : undefined;

    const content = <>
                    <MapContainer bounds={latLngBounds}  zoom={1} style={{height: "100%", zIndex: 0}}>
                <AttributionTile></AttributionTile>
                <LeafletBezier
                    path={[path]}
                    options={{
                        animate : false,
                        color : getPathColor(settings, aliasesToIRIMap, shapeProperty),
                        weight : getPathWeight(settings, aliasesToIRIMap, shapeProperty) ,
                        dashArray : getPathDashArray(settings, aliasesToIRIMap, shapeProperty)
                    }}
                />
            </MapContainer>
    </>;

    return <WithMaxButton content={content}></WithMaxButton> ;


}